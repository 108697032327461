import {CA, DEFAULT, DEFAULT_LOCALE, UAE, UK, US} from "./constantHelper";
import {apiHelper, ukApiHelper} from "./apiHelper";
import {jwtDecode} from "./jwtHelper";
import Router from "next/router";
import HmacSHA256 from "crypto-js/hmac-sha256";
import encHex from "crypto-js/enc-hex";

export const convertDate = (dateValue) => {
    const date = new Date(dateValue);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export const getLanguage = (locale) => {
    switch(locale){
        case DEFAULT_LOCALE:
            return "en";
        case UK:
            return "en-GB";
        case CA:
            return "en-CA";
        case UAE:
            return "en-AE";
        default:
            return "en";
    }
}

export const placeOrderFormData = (convertedUserToken, currLocale) => {
    const disciplineId = currLocale === UK ? 3 : 2;
    const formData = new FormData();
    formData.append("service", 3);
    formData.append("page", 1);
    formData.append("set_spacing", 1);
    formData.append("academic", 3);
    formData.append("paper", 1);
    formData.append("other_paper", '');
    formData.append("subject", 18);
    formData.append("other_subject", '');
    formData.append("formated_style", 1);
    formData.append("other_format", '');
    formData.append("source", 0);
    formData.append("discipline", disciplineId);
    formData.append("topic", `Writer's Choice`);
    formData.append("add_detail", '');
    formData.append("timezone", 'Europe/London');
    formData.append("deadline", '');
    formData.append("duration", '');
    formData.append("coupon_code", '');
    formData.append("slide", 0);
    formData.append("chart", 0);
    formData.append("preferred_writer", 'any_writer');
    formData.append("writer_id", '');
    formData.append("additionalextra", []);
    formData.append("user_token", convertedUserToken && convertedUserToken.value ? convertedUserToken.value : '');
    formData.append("deadlineid", 0);
    formData.append("currency_id", getCurrencyId(currLocale));

    return formData;
}

export const decodeTokenToFormData = (decodeToken = {}, convertedUserToken = {}) => {
    const formData = new FormData();
    formData.append("service", decodeToken.service || 3);
    formData.append("page", decodeToken.page || 0);
    formData.append("set_spacing", decodeToken.set_spacing || 1);
    formData.append("academic", decodeToken.academic || 3);
    formData.append("paper", decodeToken.paper || 1);
    formData.append("other_paper", decodeToken.other_paper || '');
    formData.append("subject", decodeToken.subject || 18);
    formData.append("other_subject", decodeToken.other_subject || '');
    formData.append("formated_style", decodeToken.formated_style);
    formData.append("other_format", decodeToken.other_format);
    formData.append("source", decodeToken.source);
    formData.append("discipline", decodeToken.discipline);
    formData.append("topic", decodeToken.topic || `Writer's Choice`);
    formData.append("add_detail", decodeToken.add_detail || '');
    formData.append("timezone", decodeToken.timezone || 'Europe/London');
    formData.append("deadline", decodeToken.deadline || '');
    formData.append("duration", decodeToken.duration || '');
    prepareFormData(formData, decodeToken,convertedUserToken);
    return formData;
}

// due to sonar Cloud warning split decodeTokenToFormData()
const prepareFormData = (formData, decodeToken, convertedUserToken) => {
    formData.append("slide", decodeToken.slide || 0);
    formData.append("chart", decodeToken.chart);
    formData.append("preferred_writer", decodeToken.preferred_writer);
    formData.append("writer_id", decodeToken?.writer_id || '');
    formData.append("additionalextra", decodeToken.additionalextra);
    formData.append("user_token", convertedUserToken?.value || '');
    formData.append("deadlineLable", decodeToken.deadlineLable);
}

export const getCurrencyId = (currLocale) => {
    if(currLocale === UK) return 4;
    if(currLocale === CA) return 2;
    if(currLocale === UAE) return 7;
    return 1;
}

export const setOrderApiCall = (formData, discipline) => {
    return new Promise((resolve, reject)=>{
        apiHelper('setOrderV1', 'POST', formData, null).then(res => {
            if (res.data.status) {
                const data = res.data.data;
                localStorage.setItem("orderToken", data.order_token);

                const decodeOrder = jwtDecode(data.order_token);

                updateLocalStorageRightData(decodeOrder, discipline);
                const digit = decodeOrder.deadline ? decodeOrder.deadline : '';
                const duration = decodeOrder.duration ? decodeOrder.duration : '';
                const deadline = {
                    "digit": digit,
                    "durations": duration,
                    // "label": `${digit} ${duration} / ${decodeOrder.deadlineLable && decodeOrder.deadlineLable}`
                    "label": decodeOrder.duration && decodeOrder.deadline ? `${digit} ${duration} / ${decodeOrder.deadlineLable}` : 'Deadline'
                };
                localStorage.setItem('calculator_deadline', JSON.stringify(deadline));
                localStorage.setItem("calculator_service", JSON.stringify({ id: 3, label: "Writing" }));
                localStorage.setItem("calculator_pages", JSON.stringify({ value: 1, label: "Essay (Any Type)" }));
                localStorage.setItem("calculator_word", JSON.stringify({ id: 1, label: "280 words / 1 page" }));
                localStorage.setItem('currStep', 1);

                removeFromLocalStorage();
                resolve(true);
            } else {
                reject();
            }
        }).catch(error => {
            console.error(`Error: ${error}`);
            reject(error);
        });
    })
}
const updateLocalStorageRightData = (decodeOrder, discipline) => {
    const rightData = {
        discountTotal: "0.00",
        page: decodeOrder.page ? decodeOrder.page : 1,
        spacing: 'Double-spaced',
        paper: decodeOrder.calculater_labels && decodeOrder.calculater_labels.paper_label,
        // abstractPrice: decodeOrder.abstractPageprice && decodeOrder.abstractPageprice,
        discipline: discipline,
        format: 'MLA',
        preferred: '1',
        preferredWriter: 'Any Writer  (Free)',
        level: 'College'
    };

    rightData['ppSlideCost'] = decodeOrder.slideCost ? (decodeOrder.slideCost).toString() : '';
    rightData['pageCost'] = decodeOrder.pageCost ? (decodeOrder.pageCost).toString() : '';
    if (decodeOrder.service === 2) {
        rightData['ppSlideTotal'] = decodeOrder.slideTotal ? (decodeOrder.slideTotal).toString() : '';
    } else {
        rightData['totalCost'] = decodeOrder.pageTotal ? (decodeOrder.pageTotal).toString() : '';
    }

    localStorage.setItem('orderRight', JSON.stringify(rightData));
}

const removeFromLocalStorage = () => {
    localStorage.removeItem('orderViewId');
    localStorage.removeItem('onceStep');
    localStorage.removeItem('orderFileUpload');
    localStorage.removeItem('hireWriter');
    localStorage.removeItem('editCoupon');
    localStorage.removeItem('bundleId');
    localStorage.removeItem('step2OrderToken')
}

// storeRightData
export const storeRightData = (key, value) => {
    const rightData = localStorage.getItem("orderRight");

    let item = {};
    if (rightData && rightData !== '') {
        item = JSON.parse(rightData);

        item[key] = value;
        localStorage.setItem("orderRight", JSON.stringify(item));
    }
}

export const pageStaticProps = async (pageName, context) => {
    const DEFAULT_META = process.env.defaultMeta;
    const locale = context.locale === DEFAULT ? US : context.locale;

    const subMenuData = await ukApiHelper(
        `subMenu`,
        'GET',
        null,
        null,
        locale
    );
    const sub_menu_data = subMenuData.data.status ? subMenuData.data.data : null;

    const res = await ukApiHelper(
        `seoV1?page=${pageName}&is_live=${process.env.isApiLive}`,
        'GET',
        null,
        null,
        locale
    );
    const meta = res.data.status ? res.data.data : DEFAULT_META;

    const resHrefLang = await ukApiHelper(
        `hreflang?page=${pageName}`,
        'GET',
        null,
        null,
        locale
    );
    const hrefLang = resHrefLang.data.status ? resHrefLang.data.data : null;

    return {
        meta,
        sub_menu_data,
        hrefLang,
        locale
    }

}

export const pageStaticReviewsProps = async (pageName, context) => {
    const staticProps = await pageStaticProps(pageName, context);

    const { locale } = staticProps;

    const resReviews = await ukApiHelper(
        `webReviewsV1?page=${pageName}`,
        "GET",
        null,
        null,
        locale
    );
    const reviews = resReviews.data.status ? resReviews.data.data : null;

    return {
        ...staticProps,
        reviews
    }
}

export const emailChangeHandler = (event, setEnteredEmail) => {
    setEnteredEmail(event.target.value);
}

const validateEmailHandler = (setEmailInValidMsg) => {
    setEmailInValidMsg("Email should be either valid or not be blank.");
}

export const submitHandler = (event, setEmailInValidMsg, enteredEmail, currLocale) => {
    event.preventDefault();
    validateEmailHandler(setEmailInValidMsg);
    if (enteredEmail === "") {
        setEmailInValidMsg("Please Enter Email");
    }
    else {
        setEmailInValidMsg('');
        const formData = new FormData();
        formData.append("email", enteredEmail);
        formData.append("fx", "unlockDiscount");

        ukApiHelper('createAccountV1', 'POST', formData, null, currLocale)
        .then(async res => {
            const response = res.data;
            if (response.status) {
                // Set Data in localstrorage
                const now = new Date();
                const item = {
                    value: response.data,
                    expiry: now.getTime() + (12 * 60 * 60 * 1000)
                };

                localStorage.setItem("token", JSON.stringify(item));
                await Router.push('/order');
                setEmailInValidMsg('');
            }
            else {
                setEmailInValidMsg(res.data.message);
            }
        })
        .catch(error => console.error(`Error: ${error}`));
    }
}
export const convertDateToTZD=(date)=>{
    const currDate = new Date(date);
    const dateTimeIsoString = currDate.toISOString();
// split by dot
    const currDateTime = dateTimeIsoString.split('.')[0];
    const timeZoneOffset = currDate.getTimezoneOffset();
    const positiveOffset = Math.abs(timeZoneOffset)
    const timeOffsetInHours = -(timeZoneOffset/60)
    const minZone = (positiveOffset - Math.floor(timeOffsetInHours) * 60)
    const symbolOffset = timeZoneOffset > 0 ? '-' : '+' ;
    const hourOffset = Math.floor(timeOffsetInHours) < 10 ? 0 : '';
    const minOffset = minZone < 10 ? 0 : '';
    const tzd = symbolOffset + hourOffset + Math.floor(timeOffsetInHours) + ":" + minOffset + minZone
    return currDateTime + tzd;
}

export const setInterComData = (userToken) => {

    const formData = new FormData();
    formData.append("user_token", userToken);

    apiHelper('getintercomdata', 'POST', formData, null)
        .then(res => {

            if (res.data.status) {
                const data = res.data.data;

                const secret = 'r12Ntl2v4JtgWI8Hri7GBkZqb0YhlXcdBoKRmkXj';
                const stringID = data.user_id.toString();
                const hash = HmacSHA256(stringID, secret);
                data.user_hash = encHex.stringify(hash);
                if (!!window.Intercom && typeof window !== 'undefined')
                    window.Intercom('boot', data);
            }
        })
        .catch(error => console.error(`Error: ${error}`));
}