export const OTHERCARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVV = [/[0-9]/, /\d/, /\d/, /\d/];

export const DEFAULT_LOCALE = 'default';
export const DEFAULT = "default";
export const US = "us";
export const UK = "uk";
export const CA = "ca";
export const UAE = "uae";
export const DEFAULT_LOCALES_LIST = [DEFAULT, US];