import React, {useEffect, useState} from "react";
import {useRouter} from 'next/router';
// for header and footer
import dynamic from "next/dynamic";
// api helper
import {apiHelper} from "../helper/apiHelper";
// common css
import '../styles/globals.scss';
import {appWithTranslation} from 'next-i18next';
import {CA, DEFAULT, UAE, UK} from "../helper/constantHelper";
import {getLanguage} from "../helper/utilHelper";
import SSRProvider from 'react-bootstrap/SSRProvider';
import Context from "../context/context";

const Layout = dynamic(() => import('../components/layout'));
const PromoDialog = dynamic(() => import("../components/Dialog"));

export function reportWebVitals(metric) {
  try {
    let id = metric.id;
    let label = metric.label;
    let name = metric.name;
    let value = metric.value;
    if (window.gtag) {
      window.gtag('event', name, {
        event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        event_label: id, // id unique to current page load
        non_interaction: true // avoids affecting bounce rate.
      });
    }
  } catch (error) {
    console.log("error " + error);
  }
}
const subscribe = async () => {
  let sw = await navigator.serviceWorker.ready;
  let push = await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey:
      'BJ9PhAbQ3OjBuk9BFZeNRUPlv_JGfdpmAVkWmFFzJmQ5FLDVVNuzOTdCrINH05xp2ExMPkc5jfVK9Jk77KHKpXE'
  });

  const formData = new FormData();
  formData.append('subscription_data', JSON.stringify(push));
  apiHelper('subscribe', "POST", formData, null)
  .then(() => {
  })
  .catch((error) => console.error(`Error: ${error}`));
}

function MyApp({ Component, pageProps }) {
  const [showPromoDialog, setShowPromoDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState([]);

  const router = useRouter();

  const locale = pageProps && pageProps.currLocale;
  useEffect(() => {
    const desiredLang = getLanguage(locale);
    document.documentElement.lang = desiredLang;

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== desiredLang) {
        document.documentElement.lang = desiredLang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    }
  }, [locale]);

  const handleClose = () => {
    setShowPromoDialog(false);
    sessionStorage["popupShown"] = true;
  }

  useEffect(() => {
    let isPromoVisible = false;
    const body = document.body, html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    sessionStorage.clear();

    if ('serviceWorker' in navigator) {
      addEventListener('load', async () => {
        await navigator.serviceWorker.register('/sw.js');
      });
    }
    subscribe();

    function showDialogOnScroll() {
      if(window.pageYOffset > (docHeight/2)) {
        sessionStorage["popupShown"] && !!sessionStorage["popupShown"] ? setShowPromoDialog(false) : setShowPromoDialog(true);
      } else {
        setShowPromoDialog(false);
      }
    }

    apiHelper("us/couponOfferDialog", "GET", null, null)
      .then((res) => {
        const response = res.data;
        if (response.status) {
          isPromoVisible = response.data.coupon_dialog_pages.some(path => path.file_name === router.asPath.split("/").pop());
          setDialogContent(response.data.coupon_dialog_content);
          if (isPromoVisible) {
              window.addEventListener("scroll", showDialogOnScroll);
            return () => {window.removeEventListener("scroll", showDialogOnScroll);}
          }
        }
      })
      .catch((error) => console.error(`Error: ${error}`));

      switch(locale) {
        case UK:
          localStorage.setItem('locale', UK);
          break;
        case CA:
          localStorage.setItem('locale', CA);
          break;
        case UAE:
          localStorage.setItem('locale', UAE);
          break;
        default:
          localStorage.setItem('locale', DEFAULT);
      }
  }, []);


  return (
    <>

      <SSRProvider>
          <Context>
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
          </Context>
      </SSRProvider>

      {dialogContent ?
        <PromoDialog
          show={showPromoDialog}
          handleClose={() => { handleClose() }}
          handleResClose={() => {
            handleClose();
            setTimeout(() => { router.push('/order?discount_code=EXCLUSIVE') }, 1000);
          }}
          content={dialogContent} />
      : null}
    </>
  )
}

export default appWithTranslation(MyApp);