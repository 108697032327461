import axios from 'axios';
import Router from "next/router";

// let url = "https://e6djdtoi97.execute-api.us-east-2.amazonaws.com/dev/";
let url = process.env.API_URL;
// let url = process.env.VERCEL_ENV == 'production' ? 'https://web.cheapestessay.com/' : 'https://u3a9v7t4n4.execute-api.us-east-2.amazonaws.com/dev/';
// let url = "https://web.cheapestessay.com/";

// api calling function
export const apiHelper = (api, method, data, headers) => {
    const apiUrl = url + api;
    const auth = null;
    return promiseHelper(method, apiUrl, data, headers, auth);
}

// api calling function
export const ukApiHelper = (api, method, data, headers, locale= "us") => {
    const apiUrl = url + `${locale}/` + api;
    const auth = null;
    return promiseHelper(method, apiUrl, data, headers, auth);
}

// s3 request function
// export const s3Helper = (path, method, data, headers) => {
//     const apiUrl = 'https://s3.us-east-2.amazonaws.com/' + path;
//     const auth = null;
//     return promiseHelper(method, apiUrl, data, headers, auth);
// }

export const s3Helper = (path, method, data, headers, region='us-east-2') => {
    const apiUrl = `https://s3.${region}.amazonaws.com/` + path;
    const auth = null;
    return promiseHelper(method, apiUrl, data, headers, auth);
}

export const graphHelper = (query) => {
    // const url = 'https://d15f713mur0dkt.cloudfront.net/graphql';
    const url = 'https://contents.cheapestessay.com/graphql';
    const headers = {
        "Content-Type": "application/json"
    };
    const auth = {
        username: 'admin',
        password: 'h8spjEWAnkEGsmkLQQm4'
    };
    return promiseHelper('POST', url, JSON.stringify(query), headers, auth);
}

const promiseHelper = (method, url, data, headers, auth) => {
    
    let options = null;
    if(auth) {
        options = { method, url, data, headers, auth };
    } else {
        options = { method, url, data, headers };
    }

    return new Promise((resolve, reject) => {
        axios(options)
            .then(res => resolve(res))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    Router.push('/login');
                } else {
                    reject(error);
                }
            });
    });
}