import {createContext, useMemo, useState} from "react";
export const ContextData = createContext(null);

function Context({ children }) {
    const [data, setData] = useState("");
    const [orderId, setOrderId] = useState("");

    const contextValue = useMemo(()=>{
        return {
            data, setData, orderId, setOrderId
        }
    },[data, setData, orderId, setOrderId])

    return (
      <ContextData.Provider value={contextValue}>
        {children}
      </ContextData.Provider>
    );
}

export default Context;